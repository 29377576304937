import './style.css';
import {Map, View, Feature} from 'ol';
import TileLayer from 'ol/layer/Tile';
import ImageLayer from 'ol/layer/Image';
import WMTS, {optionsFromCapabilities} from 'ol/source/WMTS';
import ImageWMS from 'ol/source/ImageWMS';
import WMTSCapabilities from 'ol/format/WMTSCapabilities';
import WMSCapabilities from 'ol/format/WMSCapabilities';
import MousePosition from 'ol/control/MousePosition';
import {createStringXY} from 'ol/coordinate';
import {defaults as defaultControls} from 'ol/control';
import {transform} from 'ol/proj';
import {Point} from 'ol/geom';
import {Vector as LayerVector} from 'ol/layer';
import {Vector as SourceVector} from 'ol/source';
import {Style, Icon} from 'ol/style';
import Translate from 'ol/interaction/Translate';
import Collection from 'ol/Collection';
import {toLonLat} from 'ol/proj';
/*
const mousePositionControl = new MousePosition({
  coordinateFormat: createStringXY(4),
  projection: 'EPSG:900913',
  // comment the following two lines to have the mouse position
  // be placed within the map.
  className: 'custom-mouse-position',
  target: document.getElementById('mouse-position'),
});*/

let view;
let iconFeature;
let map;

const ActiveMap = {
  OLMAP: 'OLMap',
  GMAP: 'GMap',
};

let activeMap = ActiveMap.GMAP;

async function initOLMap(latitude, longitude) {

  const wmtsParser = new WMTSCapabilities();
  const wmsParser = new WMSCapabilities();

  let raadpleegDienstenWmts;
  const fetchRaadpleegDienstenWmts = fetch('https://tile.informatievlaanderen.be/ws/raadpleegdiensten/wmts?request=GetCapabilities&service=WMTS&version=1.0.0')
      .then(function (response) {
        return response.text();
      })
      .then(function (text) {
        raadpleegDienstenWmts = wmtsParser.read(text);
      });

  let raadpleegDienstenPubliekWmts;
  const fetchRaadpleegDienstenPubliekWmts = fetch('https://www.mercator.vlaanderen.be/raadpleegdienstenmercatorgeocachepubliek/service/wmts?request=GetCapabilities&service=WMTS&version=1.0.0')
      .then(function (response) {
        return response.text();
      })
      .then(function (text) {
        raadpleegDienstenPubliekWmts = wmtsParser.read(text);
      });

  let raadpleegDienstenPubliekWms;
  const fetchRaadpleegDienstenPubliekWms = fetch('https://mercator.vlaanderen.be/raadpleegdienstenmercatorpubliek/lu/wms?REQUEST=GetCapabilities&SERVICE=WMS')
      .then(function (response) {
        return response.text();
      })
      .then(function (text) {
        raadpleegDienstenPubliekWms = wmsParser.read(text);
      });

  const allData = Promise.all([fetchRaadpleegDienstenWmts, fetchRaadpleegDienstenPubliekWmts, fetchRaadpleegDienstenPubliekWms]);

// attach then() handler to the allData Promise
  return allData.then((res) => {
    const grbBsk = optionsFromCapabilities(raadpleegDienstenWmts, {
      layer: 'grb_bsk',
      //layer: 'grb_bsk_grijs',
      //layer: 'grb_sel',
      matrixSet: 'EPSG:900913',
    });

    const luGwpRv = optionsFromCapabilities(raadpleegDienstenPubliekWmts, {
      layer: 'lu:lu_gwp_rv_raster',
      //layer: 'lu:lu_gemrup_ct',
      matrixSet: 'EPSG:900913',
    });

    /*const luRemRupCt = optionsFromCapabilities(raadpleegDienstenPubliekWms, {
      //layer: 'lu:lu_gwp_rv_raster',
      layer: 'lu:lu_gemrup_ct',
      matrixSet: 'EPSG:900913',
    });*/

    /*let layerInfo = raadpleegDienstenPubliekWms.Capability.Layer.Layer.find(l => l.Name === wmsLayerName);
    var luRemRupCtExtent = layerInfo.EX_GeographicBoundingBox;
    console.log({luRemRupCtExtent})

    var crs = layerInfo.CRS;
    console.log({crs});*/
    //debugger;
    //var extent_3857 = ol.proj.transform(extent, 'EPSG:4326', 'EPSG:3857')

    //var extent_900913 = transform(luRemRupCtExtent, "CRS:84", 'EPSG:900913')
    //console.log({extent_900913});

    /*let boundingBox = layerInfo.BoundingBox.find(l => l.getAttribute("CRS") === 'EPSG:900913');
    console.log({boundingBox});
    let extent_900913 = [boundingBox.minx, boundingBox.maxx, boundingBox.miny, boundingBox.maxy];*/


    let coords = [longitude, latitude];
    let transformedCoords = transform(coords, 'EPSG:4326', 'EPSG:900913')

    view = new View({
      center: transformedCoords,
      zoom: 16,
    });

    iconFeature = new Feature({
      geometry: new Point(transformedCoords)
    });

    let mapCanvas = document.getElementById('map');

    map = new Map({
      //controls: defaultControls().extend([mousePositionControl]),
      layers: [
        new TileLayer({
          opacity: 0.7,
          source: new WMTS(grbBsk),
        }),
        new TileLayer({
          opacity: 0.3,
          source: new WMTS(luGwpRv),
        }),
        new ImageLayer({
          title: 'DSI - Gemeentelijke RUP - Contouren',
          type: 'base',
          visible: true,
          source: new ImageWMS({
            url: 'https://mercator.vlaanderen.be/raadpleegdienstenmercatorpubliek/lu/wms',
            params: {'LAYERS': 'lu_gemrup_ct'}
          })
        }),
        //new ImageLayer({title:'DSI - Gemeentelijke RUP - Deelgebieden',type: 'base', visible: true,source: new ImageWMS({ url: 'https://mercator.vlaanderen.be/raadpleegdienstenmercatorpubliek/lu/wms', params: {'LAYERS': 'lu_gemrup_dg'}})}),
        //new ImageLayer({title:'DSI - Gemeentelijke RUP - Grondvlakken',type: 'base', visible: true,source: new ImageWMS({ url: 'https://mercator.vlaanderen.be/raadpleegdienstenmercatorpubliek/lu/wms', params: {'LAYERS': 'lu_gemrup_gv'}})}),
        //new ImageLayer({title:'DSI - Gemeentelijke RUP - Lijnen',type: 'base', visible: true,source: new ImageWMS({ url: 'https://mercator.vlaanderen.be/raadpleegdienstenmercatorpubliek/lu/wms', params: {'LAYERS': 'lu_gemrup_ln'}})}),
        //new ImageLayer({title:'DSI - Gemeentelijke RUP - Lijnen indicatieve aanduiding',type: 'base', visible: true,source: new ImageWMS({ url: 'https://mercator.vlaanderen.be/raadpleegdienstenmercatorpubliek/lu/wms', params: {'LAYERS': 'lu_gemrup_lnia'}})}),
        //new ImageLayer({title:'DSI - Gemeentelijke RUP - Natraject',type: 'base', visible: true,source: new ImageWMS({ url: 'https://mercator.vlaanderen.be/raadpleegdienstenmercatorpubliek/lu/wms', params: {'LAYERS': 'lu_gemrup_na'}})}),
        //new ImageLayer({title:'DSI - Gemeentelijke RUP - Overdrukken',type: 'base', visible: true,source: new ImageWMS({ url: 'https://mercator.vlaanderen.be/raadpleegdienstenmercatorpubliek/lu/wms', params: {'LAYERS': 'lu_gemrup_ov'}})}),
        //new ImageLayer({title:'DSI - Gemeentelijke RUP - Overdrukken indicatieve aanduiding',type: 'base', visible: true,source: new ImageWMS({ url: 'https://mercator.vlaanderen.be/raadpleegdienstenmercatorpubliek/lu/wms', params: {'LAYERS': 'lu_gemrup_ovia'}})}),
        //new ImageLayer({title:'DSI - Gemeentelijke RUP - Punten',type: 'base', visible: true,source: new ImageWMS({ url: 'https://mercator.vlaanderen.be/raadpleegdienstenmercatorpubliek/lu/wms', params: {'LAYERS': 'lu_gemrup_pt'}})}),
        //new ImageLayer({title:'DSI - Gemeentelijke RUP - Punten indicatieve aanduiding',type: 'base', visible: true,source: new ImageWMS({ url: 'https://mercator.vlaanderen.be/raadpleegdienstenmercatorpubliek/lu/wms', params: {'LAYERS': 'lu_gemrup_ptia'}})}),
        //new ImageLayer({title:'DSI - Gemeentelijke RUP - Schorsingen',type: 'base', visible: true,source: new ImageWMS({ url: 'https://mercator.vlaanderen.be/raadpleegdienstenmercatorpubliek/lu/wms', params: {'LAYERS': 'lu_gemrup_sc'}})}),

        new LayerVector({
          source: new SourceVector({
            features: [iconFeature]
          }),
          style: new Style({
            image: new Icon({
              scale: .7, anchor: [0.5, 1],
              src: '//raw.githubusercontent.com/jonataswalker/map-utils/master/images/marker.png'
            })
          })
        }),
      ],
      target: 'map',
      view: view,
    });

    map.on('click', function(e){
      iconFeature.setGeometry(new Point(e.coordinate));

      let lonLat = toLonLat(e.coordinate)
      panorama.setPosition(new google.maps.LatLng(lonLat[1], lonLat[0]))
    });

    mapCanvas.addEventListener('mouseover', (event) => {
      //console.log("mouseover map")
      activeMap = ActiveMap.OLMAP;
    });

    mapCanvas.addEventListener('mouseout', (event) => {
      //console.log("mouseout map")
      //activeMap = null;
    });

    const translate = new Translate({
      features: new Collection([iconFeature]),
    })

    translate.on('translateend', e => {
      let lonLat = toLonLat(e.coordinate)
      panorama.setPosition(new google.maps.LatLng(lonLat[1], lonLat[0]))
    })

    map.addInteraction(translate)

    console.log("Open layers map initialised!");

    //registerMapListeners();
  });

}

let initialized = false;

function registerMapListeners() {
  map.on('movestart', function (evt) {
    console.log("map.movestart");
  });

  map.on('moveend', function (evt) {

    if(!initialized) return;

    if (activeMap === ActiveMap.GMAP) return;

    console.log("map.moveend");

    const map = evt.map;
    const extent = map.getView().calculateExtent(map.getSize());

    console.log({extent});

    var bounds = new google.maps.LatLngBounds();

    for (var i = 0; i < extent.length; i = i + 2) {
      const coords = [extent[i], extent[i + 1]]
      const transformedPoint = transform(coords, "EPSG:900913", "EPSG:4326");
      var myLatLng = new google.maps.LatLng(transformedPoint[1], transformedPoint[0]);

      //var myLatLng = new google.maps.LatLng(coords[0], coords[1]);
      bounds.extend(myLatLng);
    }
    //console.log({bounds})
    //console.log({gmap})
    if (gmap) {
      gmap.fitBounds(bounds);
    }

  });
}

function registerGMapListeners() {
  gmap.addListener('bounds_changed', function() {

    if(activeMap === ActiveMap.OLMAP) return;

    console.log("gmap.bounds_changed");

    const bounds = gmap.getBounds();

    var ne = bounds.getNorthEast(); // LatLng of the north-east corner
    var sw = bounds.getSouthWest(); // LatLng of the south-west corder

    //var nw = new google.maps.LatLng(ne.lat(), sw.lng());
    //var se = new google.maps.LatLng(sw.lat(), ne.lng());

    let transformedNe = transform([ne.lng(), ne.lat()], "EPSG:4326", "EPSG:900913");
    let transformedSw = transform([sw.lng(), sw.lat()], "EPSG:4326", "EPSG:900913");

    console.log({bounds});

    const center = gmap.getCenter();
    const coords = [center.lng(), center.lat()]
    const transformedPoint = transform(coords, "EPSG:4326", "EPSG:900913");

    if(view) {
      //view.setCenter(transformedPoint);
      //view.setZoom(gmap.getZoom());

      //map.setExtent();
      var myExtent = [transformedSw[0], transformedSw[1], transformedNe[0], transformedNe[1]];
      console.log({myExtent});
      view.fit(myExtent , map.getSize());
    }
  });
}

function registerListeners()
{
  console.log("registerListeners >>>");
  registerMapListeners();
  registerGMapListeners();
  console.log("registerListeners <<<");
  initialized = true;
}

function initialize() {
  navigator.geolocation.getCurrentPosition(
      function (position) {


        const allMaps = Promise.all([
            initGMap(position.coords.latitude, position.coords.longitude),
          initOLMap(position.coords.latitude, position.coords.longitude)]);


        allMaps.then((res) => {
          registerListeners();
        });


      },
      function errorCallback(error) {
        console.log(error)
      }
  );

  const input = document.getElementById("pac-input");
  console.log({input});
  const searchBox = new google.maps.places.SearchBox(input);

 // let markers = [];

  // Listen for the event fired when the user selects a prediction and retrieve
  // more details for that place.
  searchBox.addListener("places_changed", () => {
    activeMap = ActiveMap.GMAP;

    const places = searchBox.getPlaces();

    if (places.length === 0) {
      return;
    }

    // Clear out the old markers.
    /*markers.forEach((marker) => {
      marker.setMap(null);
    });
    markers = [];*/

    // For each place, get the icon, name and location.
    const bounds = new google.maps.LatLngBounds();

    places.forEach((place) => {
      if (!place.geometry || !place.geometry.location) {
        console.log("Returned place contains no geometry");
        return;
      }
/*
      const icon = {
        url: place.icon,
        size: new google.maps.Size(71, 71),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(17, 34),
        scaledSize: new google.maps.Size(25, 25),
      };

      // Create a marker for each place.
      markers.push(
          new google.maps.Marker({
            map,
            icon,
            title: place.name,
            position: place.geometry.location,
          })
      );*/

      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    });
    console.log({bounds})

    const center =  bounds.getCenter();
    console.log({center})


    gmap.setCenter(center)
    panorama.setPosition(center);

    console.log("iconFeature>>")
    const positionCoords = [center.lng(), center.lat()]

    console.log({positionCoords})
    const transformedPoint = transform(positionCoords, "EPSG:4326", "EPSG:900913");
    iconFeature.setGeometry(new Point(transformedPoint));
    console.log("iconFeature<<")


    initGMap(center.lat(), center.lng())
    registerGMapListeners();
  });

}

let gmap;
let panorama;

async function initGMap(latitude, longitude) {

  var position = new google.maps.LatLng(latitude, longitude);

  var mapOptions = {
    center: position,
    zoom: 14
  };
  let gmapCanvas = document.getElementById('map-canvas');
  gmap = new google.maps.Map(gmapCanvas, mapOptions);

      gmapCanvas.addEventListener('mouseover', (event) => {
        activeMap = ActiveMap.GMAP;
  });

      gmapCanvas.addEventListener('mouseout', (event) => {

  });

  var panoramaOptions = {
    position: position,
    pov: {
      heading: 4,
      pitch: 10
    }
  };
  panorama = new google.maps.StreetViewPanorama(document.getElementById('pano'), panoramaOptions);

  gmap.setStreetView(panorama);

  panorama.addListener("position_changed", () => {
    const position = panorama.getPosition();

    const positionCoords = [position.lng(), position.lat()]

    const transformedPoint = transform(positionCoords, "EPSG:4326", "EPSG:900913");

    if(iconFeature) {
      iconFeature.setGeometry(new Point(transformedPoint));
    }

    if(view != null) {
      view.setCenter(transformedPoint);
    }
    if(gmap != null) {
      gmap.setCenter(position);
    }
  });

  console.log("Google map initialised!");

  //registerGMapListeners();
}

google.maps.event.addDomListener(window, 'load', initialize);
